<div class="login-wrapper">
  <div class="component-wrapper">
    <div class="hero-image fadein" [ngClass]="image"></div>
    <div class="hero-image fadein" [ngClass]="altImage"></div>

    <div class="component-content">
      <mat-icon svgIcon="insights_text" class="insights-text"></mat-icon>

      <router-outlet></router-outlet>
    </div>

    <mat-icon svgIcon="moodys_logo" class="moodys-logo"></mat-icon>
  </div>

  <div class="copyright">
    <div class="text">
      Moody's Analytics - Miu Insights &copy;
      <span>{{ currentYear }}</span>
    </div>
  </div>
</div>
